import React from "react"

const CheckBoxItem = ({ item, name, ...props }) => (
  <>
    <input
      className="[ checkbox ]"
      name={name}
      type="checkbox"
      id={item}
      value={item}
      {...props}
    />
    <label className="[ checkbox__label ]" htmlFor={item}>
      {item}
    </label>
  </>
)

const CheckBoxList = ({ title, name, items = [], ...props }) => (
  <li className="[ filter ]">
    <p className="[ filter__label ]">{title}</p>
    {items.map(item => (
      <CheckBoxItem key={item} name={name} item={item} {...props} />
    ))}
  </li>
)

export default CheckBoxList
