import { isEmptyObject, hasOnlyNullValues } from "./object"
export const sort = sortBy => {
  return (a, b) => {
    if (sortBy === "alphabetical")
      if (a.podName.toLowerCase() < b.podName.toLowerCase()) return -1
      else return 1
    if (sortBy === "county")
      if (a.county.toLowerCase() < b.county.toLowerCase()) return -1
      else return 1
    if (sortBy === "schoolName")
      if (a.schoolName.toLowerCase() < b.schoolName.toLowerCase()) return -1
      else return 1
    if (sortBy === "slots") return a.openSlots - b.openSlots
    if (sortBy === "cost") return +a.cost - +b.cost
    if (sortBy === "size") return +a.podSize - +b.podSize
  }
}

export const filter = filters => {
  return item => {
    if (isEmptyObject(filters) || hasOnlyNullValues(filters)) return true

    let valid = false
    for (let [key, value] of Object.entries(filters)) {
      if (value === null) continue
      if (Array.isArray(value) && Array.isArray(item[key]))
        valid = value.every(i => item[key].includes(i))
      else if (key === "zipCode")
        valid = item[key].toLowerCase().includes(value.toLowerCase())
      else if (value == item[key]) valid = true
      else return false

      if (!valid) return false
    }
    return valid
  }
}

export const displayPodCost = pod => {
  let cost = ""
  if (+pod.cost > 0) {
    cost += `$${pod.cost}`
    if (!pod.paymentCycle || pod.paymentCycle === "None") return cost
    else cost += `/${pod.paymentCycle}`
  } else cost = "Free"
  return cost
}

export const displayPodVenue = pod => {
  let venue = ""
  if (pod.schoolName) venue += `${pod.schoolName} - `
  if (pod.city) venue += `${pod.city}, `
  venue += `${pod.state} ${pod.zipCode} (${pod.county})`
  return venue
}

export const displayCultureSection = (pod, culture) => {
  return culture.some(({ value }) => pod[value])
}
