import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import CheckList from "./CheckBoxList"
import { InputItem, SelectItem } from "../forms"
import { onChange, onCheck, preventNegativeInput } from "../../helpers/filters"
import { transformStates } from "../../helpers/states"

const Filters = ({ setFilters }) => {
  const { allStates, podAttributes } = useStaticQuery(query)
  const states = transformStates(allStates.nodes)
  return (
    <div>
      <input type="checkbox" name="toggleSwitch" id="toggleSwitch" />
      <aside className="[ flow ] [ shadow-box ] [ filters ]">
        <section id="filters">
          <label className="toggle-button-close" htmlFor="toggleSwitch"></label>
          <h3>Filters</h3>
          <ul className="[ flow ]">
            <SelectItem
              label="Term"
              name="term"
              items={podAttributes.terms}
              onChange={onChange(setFilters)}
            />
            <SelectItem
              label="Grade"
              name="grade"
              items={podAttributes.grades}
              onChange={onChange(setFilters)}
            />
            <SelectItem
              label="Pod location"
              name="location"
              items={podAttributes.location}
              onChange={onChange(setFilters)}
            />
            <SelectItem
              label="State"
              name="state"
              items={states}
              onChange={onChange(setFilters)}
            />
            <SelectItem
              label="Instructor"
              name="instructor"
              items={podAttributes.instructors}
              onChange={onChange(setFilters)}
            />
            <SelectItem
              label="Curriculum"
              name="curriculum"
              items={podAttributes.curriculums}
              onChange={onChange(setFilters)}
            />
            <InputItem
              label="ZIP Code"
              type="text"
              name="zipCode"
              inputMode="numeric"
              min="0"
              onKeyDown={preventNegativeInput}
              onChange={onChange(setFilters)}
            />
            <SelectItem
              label="Pod size"
              name="podSize"
              items={podAttributes.podSize}
              onChange={onChange(setFilters)}
            />
          </ul>
        </section>
        <section id="personalization">
          <h3>Personalization</h3>
          <ul className="[ flow ]">
            <CheckList
              title="Safety requirements"
              name="safety"
              items={podAttributes.safety}
              onChange={onCheck(setFilters)}
            />
            <CheckList
              title="Preferences"
              name="preferences"
              items={podAttributes.preferences}
              onChange={onCheck(setFilters)}
            />
            <CheckList
              title="Technology"
              name="technology"
              items={podAttributes.technology}
              onChange={onCheck(setFilters)}
            />
          </ul>
        </section>
      </aside>
      <label className="toggle-button-open" htmlFor="toggleSwitch"></label>
    </div>
  )
}

export default Filters

const query = graphql`
  {
    allStates {
      nodes {
        long
        short
      }
    }
    podAttributes {
      terms
      grades
      location
      instructors
      curriculums
      podSize
      safety
      preferences
      technology
    }
  }
`
