import React from "react"
import PodItem from "./PodItem"

const PodList = ({ list }) => {
  return (
    <ul className="[ pod-list ]">
      {list?.length ? (
        list.map(pod => <PodItem key={pod.id} pod={pod} />)
      ) : (
        <h2>No Matching results</h2>
      )}
    </ul>
  )
}

export default PodList
