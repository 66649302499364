import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const SortBy = ({ setSortBy }) => {
  const onChange = ({ target: { value } }) => setSortBy(value)
  const { allSortingOptions } = useStaticQuery(query)
  return (
    <div className="sort-pods">
      <label htmlFor="sortPods">Sort by</label>
      <select name="sort" id="sortPods" className="select" onChange={onChange}>
        {allSortingOptions.nodes.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SortBy

const query = graphql`
  {
    allSortingOptions {
      nodes {
        label
        value
      }
    }
  }
`
