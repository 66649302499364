import React from "react"
import { Link } from "gatsby"
import { displayPodCost, displayPodVenue } from "../../helpers/pods"

const PodItem = ({ pod }) => {
  return (
    <li className="[ radius ] [ pod-card ]">
      <Link data-status={pod.status} to={`/profile?id=${pod.id}`}>
        <div className="pod-card__title">
          <p className="pod-name">{pod.podName}</p>
          <p className="pod-info">
            <span>{displayPodVenue(pod)}</span>
            {pod.grade && <span> - {pod.grade}</span>}
          </p>
        </div>
        <div className="mirror">
          <p className="[ pod-card__details ]">
            <span>{pod.location}</span>
            <span>{pod.curriculum}</span>
            <span>{pod.instructor}</span>
            <span>{pod.podSize} Openings</span>
          </p>
          <div className="button small">Learn more</div>
        </div>
        <p className="pod-card__cost">
          <span>{displayPodCost(pod)}</span>
        </p>
      </Link>
    </li>
  )
}

export default PodItem
