import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useQuery } from "react-query"

// Layout
import KnockOut from "../components/layout/KnockOut"
import { Filters, PodList, SortBy } from "../components/find"

// Utility
import { Pods } from "../config/db"
import { sort, filter } from "../helpers/pods"

const FindPage = () => {
  const { data: podList, isLoading, isError } = useQuery(
    "Find - pods",
    Pods.list,
    {
      retry: 1,
    }
  )
  const [sortBy, setSortBy] = useState("alphabetical")
  const [filters, setFilters] = useState({})
  const list = podList?.filter(filter(filters)).sort(sort(sortBy))
  return (
    <>
      <Helmet>
        <title>Find a pod</title>
      </Helmet>
      {isLoading ? (
        <KnockOut />
      ) : isError || !list ? (
        <h2 className="[ wrapper ]">There was an error getting the pods</h2>
      ) : (
        <div className="[ wrapper ] [ filter-layout ]">
          <header className="[ mirror ] [ find-header ]">
            <h2>
              Showing {list?.length} {list.length === 1 ? "pod" : "pods"}{" "}
              {filters.zipCode && `near ${filters.zipCode}`}
            </h2>
            <SortBy setSortBy={setSortBy} />
          </header>
          <Filters setFilters={setFilters} />
          <PodList list={list} isLoading={isLoading} />
        </div>
      )}
    </>
  )
}

export default FindPage
